<template>
  <div>
    <div class="liveStatus2" >
      <div class="boxCol flexB" style="height: 100%;" :style="{ 'height': typeChangeShowHeight + 'px' }">
        <div class="tabBox liveWrap" style="width: 90%;">
          <div class="flexL" style="justify-content: space-evenly;">
            <div class="live">
              <div ref= "map_div" style=" min-width: 100%; min-height: 100%; height: 100%; ">
                <Map ref="map" style="width: 100%; height: 100%; position: absolute; z-index: 2; border: 1px solid #808080;" :loadMapName="loadMap" @map-move-center="onMapCenter"/>
                <img src="@/assets/images/map/map_empty_img.png" style = "z-index: 1; position: relative; width: 100%; height: auto; object-fit: fill;" />
              </div>
            </div>
            <template v-for="(index) in 8" >
              <div class="live" :key="index">
                <span class="channel" style="z-index:5; width:44px; height:30px; text-align:center; line-height: 30px; position: absolute; top: 15px; right: 15px; background: #ff3500; color:white; font-weight: bold; border-radius: 15px; " >{{ index }}</span>
                <div style="width: 100%;" v-if="getLiveData(index) != undefined" v-show="getLiveData(index).isLoad == true">
                  <div v-show="getLiveData(index).isLoad == true" class="flex" :class="{ 'live-active' : ( currentLive != null && currentLive.channelId == getLiveData(index).channelId )}" style="z-index: 2; width: 100%; height: 100%; opacity: 1; background-color: transparent; color: white; position: absolute; bottom: 0; " @click="clickLive(getLiveData(index))" ></div>
                  <Video class="live-video" :src="getLiveUrl(getLiveData(index))" :isVod="false" :isAuto = "true" :isControls="false" :playerId="getLiveData(index).channelId" @isLoadedmetadata="playerLoadData" />
                  <a style="right: 5px; line-height: 28px; position: absolute; bottom: 5px; z-index: 10;" @click="playerOpen('live', getLiveData(index).channelId)">
                    <img src="@/assets/images/icon_svg/ic_full.svg" alt="icon"/>
                  </a>
                </div>
                <template v-if="(getLiveData(index) == undefined || getLiveData(index).isLoad == false)">
                  <div style="width: 100%; z-index: 4; border: 1px solid #808080;" v-if="getLiveData(index) == undefined || getLiveData(index).isLoad == false" class="novideo">
                    <span>{{$t("ready-live")}}</span>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
        <div class="box" style="background: #000000; width: 10%; min-width: 192px; margin-bottom: 0; overflow-y: auto;">
          <div class="flexL" v-for="(index) in 8" :key="index" style="margin-bottom: 13px;" @click="clickLive(getLiveData(index))">
            <p class="bold live-box" style="background: #333333; width: 100%; text-align: center; padding: 2px 0; margin-bottom: 3px;" :class="{ 'active' : ( currentLive != null && getLiveData(index) != null &&currentLive.channelId == getLiveData(index).channelId )}">채널 0{{index}}</p>
            <template v-if="getLiveData(index) == null || getLiveData(index).isLoad == false">
              <div class="ch_info flex" >
                <div class="liveInfoTitle">드론명</div>
                <div class="liveInfoCont">-</div>
              </div>
              <div class="ch_info flex">
                <div class="liveInfoTitle">모델명</div>
                <div class="liveInfoCont">-</div>
              </div>
              <div class="ch_info flex">
                <div class="liveInfoTitle">소속</div>
                <div class="liveInfoCont">-</div>
              </div>
              <div class="ch_info flex">
                <div class="liveInfoTitle">조종사</div>
                <div class="liveInfoCont">-</div>
              </div>
            </template>
            <template v-if="getLiveData(index) != null && getLiveData(index).isLoad == true">
              <div class="ch_info flex" >
                <div class="liveInfoTitle">드론명</div>
                <div class="liveInfoCont">{{getLiveData(index).assignedDeviceName }}
                </div>
              </div>
              <div class="ch_info flex">
                <div class="liveInfoTitle">모델명</div>
                <div class="liveInfoCont">{{getLiveData(index).assignedDeviceModelName }}</div>
              </div>
              <div class="ch_info flex">
                <div class="liveInfoTitle">소속</div>
                <div class="liveInfoCont">{{getLiveData(index).assignedDepartmentName}}</div>
              </div>
              <div class="ch_info flex">
                <div class="liveInfoTitle">조종사</div>
                <div class="liveInfoCont">{{getLiveData(index).assignedUserName}}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.live-active {
  border: 3px solid #e34307;
}
.live-box {
  /*color: #00ff44;*/
  color: white;
}
.live-box.active {
  color : #e34307;
}
.ch_info{
  width: 100%;
}
.liveInfoTitle{
  width: 45px;
  margin-left: 8px;
  color: #d3d3d3;
  font-size: 1.2rem;
}
.liveInfoCont{
  color: white;
  font-size: 1.2rem;
}
</style>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import Video from "@/components/video/videoPlayer";
import { fetchEvent } from "@/api/event";

import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";

import { baseUrl } from "@/config/env";
import { mapState } from "vuex";
import { fetchChannelList, getLiveUrl } from "@/api/channel";
import { fetchPointList, getPointTypeName } from "@/api/point";
import {playerPopOpen} from "@/util/popup";
import {getDefaultMapZoomLevel} from "@/util/common";
import {fetchLocation} from "@/api/location";

export default {
  name: "LiveStatus",
  components: {
    Video, Map
  },
  data() {
    return {
      eventId : null,
      index: 0,
      accountId: localStorage.getItem("accountId"),
      baseUrl: baseUrl,
      moment: moment,
      currentPage: 0,
      total: 0,
      size: 8,
      liveList: [],
      viewChannelList : [],
      currentLive: null,
      eventInfo : null,
      loadMap : 'kakao',
      windowHeight: window.innerHeight,
      typeChangeShowHeight: 0,
      isLocationReq : false,
    };
  },
  computed: {
    ...mapState({
      gnbVisible: state => state.store.gnbVisible,
      userType: state => state.store.userType,
    }),
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    typeChangeUpShowHeight(value){
      var upHeight = value;
      if(upHeight != this.typeChangeShowHeight) {
        upHeight = this.typeChangeShowHeight;
      }
      this.typeChangeShowHeight = upHeight;
    },
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 3 });
    window.addEventListener('resize', this.handleResize);
    this.typeChangeShowHeight = (this.windowHeight - 90) ;
  },
  async mounted() {
    this.eventId = this.$route.params.eventId;
    await this.getLiveList();
    this.getEvent();

    this.positionInterval = setInterval( () => {
      this.updateMapPosition()
    }, 1000);

    this.lineInterval = setInterval( () => {
      this.updateMapLine()
    }, 2000);

    this.interval = setInterval(() => {
      this.getLiveList();
    }, 3000);

    // 1. ResizeObserver 객체 만들기
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const {width, height} = entry.contentRect;
        width;
        height;
        this.$refs.map.setLayout();
      }
    });

    // 2. 감지할 요소 추가하기
    observer.observe(this.$refs.map_div);
  },
  methods: {
    //페이지 리사이즈
    handleResize(){
      this.windowHeight = window.innerHeight;
      this.typeChangeShowHeight = this.windowHeight - 90;
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function(){
        this.setMapSetting();
        this.getEventPoint();
        this.onMapCenter();

        if(this.currentLive != null){
          this.channelToMapMarker(this.currentLive);
          this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
          this.$refs.map.onLngLatToMoveCenter(this.currentLive.lng, this.currentLive.lat);
        }
      })
    },
    setMapSetting(){
      this.$refs.map.onShowCenterPoint();
      this.$refs.map.onShowButtonTypeChange();
      this.$refs.map.onClickShowLine(true);
    },
    async getEventPoint() {
      let params = {
        eventId : this.eventId,
        serverTime : null,
      };
      await fetchPointList( params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },
    onMapCenter(){
      if(this.eventInfo == null) {
        return;
      }
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      }
    },
    playerLoadData(id, isLoad){
      var isFindIndex = (data) => {
        return data.channelId == id;
      };
      var findIndex = this.viewChannelList.findIndex(isFindIndex)
      if(findIndex != -1) {
        this.viewChannelList[findIndex].isLoad = isLoad;
        this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
      }
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        this.eventInfo = res.data.data;
        this.initMapData(this.currentLang);
      });
    },

    /**
     * 화면 보여주는 데이터의 인덱스 보정 후 값 가져오기
     * @param index
     */
    getLiveData(index){
      if(this.viewChannelList[index - 1] == undefined) {
        return null;
      }
      return this.viewChannelList[index - 1];
    },
    itemHandler(index){
      let channel = this.getLiveData(index);
      if(channel == null) {
        return;
      }

    },
    getLiveUrl(channelData){
      var url = getLiveUrl(channelData);
      if(url == '-') {
        return ''
      }
      return url
    },
    channelToMapMarker(channelData){
      var isFindIndex = (data) => {
        return data.channelId == channelData.channelId;
      };
      var findIndex = this.viewChannelList.findIndex(isFindIndex);
      let mapDataType = MapDataType.ID_DRONE
      let pointMapMarker = new MapMarkerData();
      pointMapMarker.id = channelData.channelId;
      pointMapMarker.lng = channelData.lng;
      pointMapMarker.lat = channelData.lat;
      pointMapMarker.rotate = channelData.azimuth;
      pointMapMarker.name = findIndex + 1;
      var isMain = false;
      if(this.currentLive != null && this.currentLive.channelId == channelData.channelId) {
        isMain = true;
      }
      pointMapMarker.isMain = isMain;
      if(this.$refs.map.onIsMarker(mapDataType,channelData.channelId ) ) {
        this.$refs.map.onModifyMarkerAt(mapDataType, pointMapMarker);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, pointMapMarker);
      }
    },

    clickLive(live){
      this.$refs.map.onDeleteLineAt(MapDataType.ID_DRONE, live.channelId)
      if(this.currentLive == live){
        this.currentLive = null;
        if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
          this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
        } else {
          this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
        }
        return;
      }
      this.currentLive = live;
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      this.$refs.map.onLngLatToMoveCenter(this.currentLive.lng, this.currentLive.lat);
    },

    async updateMapPosition() {
      let params = {
        eventId :  this.eventId,
      };

      await fetchChannelList(params).then((res) => {
        let content = res.data.data.content;
        if(content.length > 0) {
          content.forEach(item => {
            var isFindIndex = (data) => {
              return data.channelId == item.channelId;
            };
            var findIndex = this.viewChannelList.findIndex(isFindIndex);
            if(findIndex != -1) {
              if(this.viewChannelList[findIndex].isLoad == true){
                this.channelToMapMarker(item);
              }
            }
          });
        }
      });
    },
    async getLiveList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        assignedStatus: "Assigned",
        eventId : this.eventId,
      };
      await fetchChannelList(params).then((res) => {
        this.total = res.data.data.total;
        this.liveList = res.data.data.content;

        //채널 추가 및 삭제 로직
        //삭제 아이디 찾기 위한 데이터 넣기
        let delIds = [];
        this.viewChannelList.forEach(item => {
          delIds.push(item.channelId.toString());
        });

        //채널 기존 채널 있는지 확인 및 삭제 아이디 에서 삭제
        this.liveList.forEach(live => {
          let findIndex = delIds.findIndex(item => item == live.channelId);
          if(findIndex != -1) {
            delIds.splice(findIndex,1);
          }
        });

        //삭제 아이디 있는 경우 채널에서 삭제
        delIds.forEach(delId => {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == delId);
          if(findIndex != -1){

            let channel = this.viewChannelList[findIndex];
            if(this.currentLive != null && this.currentLive.channelId == channel.channelId) {
              this.clickLive(channel)
            }
            this.$refs.map.onDeleteMarkerAt(MapDataType.ID_DRONE, channel.channelId);
            this.viewChannelList.splice(findIndex,1);
          }
        })

        //채널 추가
        for(var i = 0; i < this.liveList.length ; i++) {
          let live = this.liveList[i];
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == live.channelId);
          if(findIndex == -1){
            live.isLoad = false;
            this.viewChannelList.push(live)
          }
        }
      });
    },

    getChannelRefId(channel){
      var refId = null;
      if(channel.assignedDeviceType == "Mobile") {
        refId = channel.assignedWorkerId;
      } else {
        refId = channel.assignedDeviceLogId;
      }
      return refId;
    },
    updateMapLine(){
      if(this.currentLive == null) {
        return;
      }
      var refId = this.getChannelRefId(this.currentLive)
      if(refId != null) {
        this.getLocation(refId)
      }
    },
    getLocation(refId){
      if(this.isLocationReq == true) {
        return;
      }
      this.isLocationReq = true;

      let mapDataType = MapDataType.ID_DRONE;
      let offset = this.$refs.map.onGetLineSize(mapDataType, refId);
      let params = {
        refId: refId,
        eventId: this.eventId,
        offsetIndex: offset,
      };
      fetchLocation(params).then((res) => {
        this.isLocationReq = false;
        if (res.data.result == 0) {
          if (res.data.data.content.length > 0) {
            res.data.data.content.sort(function (a, b) {
              return a.index - b.index;
            })
            this.createMapLine(MapDataType.ID_DRONE, refId, res.data.data.content);
          }
        }
      }).catch((e)=>{
        e;
        this.isLocationReq = false;
      });

    },
    createMapLine(mapDataType, id, locationGroup) {
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      if (this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    playerOpen(type,id) {
      playerPopOpen(type,id);
    },
    async handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.viewChannelList = [];
      await this.getLiveList();
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.lineInterval);
    clearInterval(this.positionInterval);
  },
};
</script>
